//Search agent
.search-agent {
  padding: govuk-spacing(4);

  &__agentName {
    display: flex;
    align-items: center;
  }

  &__actionContainer {
    font-size: 1.1875rem;
    text-align: right;
  }
}

//Search agent error summary
.search-agent-error-summary .govuk-error-summary__title {
  font-size: 19px;
  margin-bottom: govuk-spacing(2);
}

.search-agent-error-summary .govuk-error-summary__list {
  font-size: 16px;
}

//Search agent mobile widget
.search-agent-widget-mobile {
  display: block;
  margin-top: govuk-spacing(2);
  padding-bottom: 50px;
}

.search-agent-widget-mobile--hide {
  display: none;
}

.search-agent__pageIntro {
  padding-bottom: govuk-spacing(1);
  border-bottom: 2px solid govuk-colour('black');
}

//Search agent desktop widget
.search-agent-widget {
  margin-top: govuk-spacing(2);
}

@media screen and (max-width: 640px) {
  .search-agent-widget {
    display: none;
  }
}
