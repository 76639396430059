.govuk-input:focus,
.govuk-input.\:focus {
  outline: 3px solid #ffbf47;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}

.lb-documentation-example {
  position: relative;
  overflow: hidden;
  border: 1px solid #bfc1c3;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 30px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.lb-documentation-example:before {
  content: 'PRÍKLAD';
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.21053em 0.78947em 0.21053em 0.78947em;
  font-family: 'Source Sans Pro', 'Arial', sans-serif;
  font-weight: 400;
  text-transform: none;
  font-size: 12px;
  line-height: 1.25;
  background: #bfc1c3;
  color: white;
}

.lb-documentation-example:after {
  content: '';
  display: block;
  clear: both;
}

@media (min-width: 641px) {
  .lb-documentation-example {
    padding-top: 45px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 641px) {
  .lb-documentation-example:before {
    font-size: 14px;
    line-height: 1.42857;
  }
}
