.vpm-no-border-bottom {
  border-bottom: 0;
}

.vpm-declined-message {
  border-color: #d0190f;
}

//Negative margin top classes
.govuk-\!-margin-top--3 {
  margin-top: -(govuk-spacing(3));
}

//Colors, borders, bgcolors
.vpm-color-disabled {
  color: govuk-colour('dark-grey');
}

.vpm-display-none {
  display: none !important;
}

.vpm-border-disabled {
  border-color: govuk-colour('dark-grey');
}

.vpm-border-black {
  border: 2px solid govuk-colour('black');
}

.vpm-background-color-grey {
  background-color: govuk-colour('lighter-grey');
}

.vpm-background-color-white {
  background-color: govuk-colour('white') !important;
}

//Text aligning
.vpm-text-align-right {
  text-align: right;
}

.vpm-text-align-center {
  text-align: center;
}

.vpm-vertical-align-middle {
  vertical-align: middle;
}
