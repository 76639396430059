//Fix footer-extended
.idsk-footer-extended {
  //Fix for big size of footer logo
  .idsk-footer-extended-logo {
    max-height: 4rem;
  }

  .idsk-footer-extended-main-content {
    //Fix links in footer doesnt get styles as they should get
    .govuk-link {
      text-decoration-thickness: max(1px, 0.0625rem);
      text-underline-offset: 0.1em;
    }

    .govuk-link:hover {
      text-decoration-thickness: max(3px, 0.1875rem, 0.12em);
      -webkit-text-decoration-skip-ink: none;
      text-decoration-skip-ink: none;
      -webkit-text-decoration-skip: none;
      text-decoration-skip: none;
    }
  }

  .idsk-footer-extended-feedback {
    .idsk-footer-extended-feedback-question-info-usefull a {
      color: white !important;
    }
  }
}

//Fix feedback container when custom message is passed
@media screen and (max-width: 640px) {
  .idsk-footer-extended-feedback-container {
    padding: govuk-spacing(3) 0;
  }

  .idsk-footer-extended-feedback-question-info-usefull {
    display: block;
  }
}

//Fix assets for header-web
.idsk-header-web__link-arrow-mobile {
  -webkit-mask: url('../images/navigation-arrow-mobile.svg') no-repeat center;
  mask: url('../images/navigation-arrow-mobile.svg') no-repeat center;
}

//Fix assets for tabs
.idsk-tabs__tab-arrow-mobile {
  -webkit-mask: url('../images/navigation-arrow-mobile.svg') no-repeat center;
  mask: url('../images/navigation-arrow-mobile.svg') no-repeat center;
}

//There is part of header, which has no bg and you can see the content threw it
.idsk-header-web {
  background: govuk-colour('white');
}

//Fix search results search button size
.idsk-search-component__button svg {
  width: 1.125rem;
  height: 1.125rem;
}

//Fix opening filter panels for all job offers on IE
.idsk-search-results__link-panel {
  height: 100%;
}

//Fix vertically aligned radios spacing
.govuk-radios__item {
  margin-bottom: govuk-spacing(2);
}

//Fix inconsistency with example on IDSK page (without this focus box shadow is too big and it shouldn't even be here )
.govuk-input--error {
  &:focus {
    box-shadow: none;
  }
}

//Hide open/close all accordion button
.govuk-accordion__open-all {
  visibility: hidden !important;
}

//Info mark icon in job offer detail waiting for approval
.vpm-info-mark-width-40 {
  svg {
    width: 40px;
  }
}

//Infomark with text in activation of acount confirmation screen
.vpm-info-mark-with-text {
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
}

//Inline buttons
.govuk-button-group {
  margin-right: -15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;

  .govuk-button {
    margin-right: 15px;
  }
}

//Fix table breaking page
@media screen and (max-width: 500px) {
  .vpm-table-overflow-x {
    overflow-x: scroll !important;
  }
}

//Fix homepage intro blocks responsiveness
@media screen and (max-width: 800px) {
  .vpm-homepage-intro-blocks {
    .govuk-grid-column-one-third {
      width: 100% !important;
    }
  }
}

//Fix inline buttons break whole page
@media screen and (max-width: 640px) {
  .govuk-button-group {
    margin-right: 0 !important;
  }
}

//Fix publish data checkbox indentation
@media screen and (max-width: 640px) {
  .vpm-publish-data-checkbox {
    padding-top: 0 !important;
    margin-bottom: govuk-spacing(4);
  }
}
