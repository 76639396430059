//Make search inputs position sticky
.idsk-search-results__link-panel {
  .idsk-search-results__list {
    padding-top: 0;

    > .govuk-form-group {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: govuk-colour('white');
    }
  }
}

.idsk-search-results__content__picked-filters.govuk-grid-column-full {
  padding-bottom: 5px;
}

//All job offers - search results component
.vpm-search-results-container:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

@media screen and (min-width: 769px) {
  .vpm-search-results-container {
    .idsk-search-results {
      .idsk-search-results__content.govuk-grid-column-three-quarters {
        padding: 0;

        .govuk-grid-column-one-quarter {
          padding-left: 0;
        }

        .idsk-search-results__content__picked-filters.govuk-grid-column-full.idsk-search-results--invisible__mobile,
        .idsk-search-results__content__page-changer.govuk-grid-column-full {
          width: 100%;
          margin-left: 0;
        }

        .idsk-search-results__content__all.govuk-grid-column-full {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 641px) {
  .vpm-search-results-container {
    .idsk-search-results__content__picked-filters.govuk-grid-column-full.idsk-search-results--invisible__mobile {
      width: 100%;
      margin-left: 0;
    }

    .idsk-search-results__content.govuk-grid-column-three-quarters {
      padding-right: 0;
    }

    .idsk-search-results__content__all.govuk-grid-column-full {
      padding: 0;
    }

    .idsk-search-results__content__page-changer.govuk-grid-column-full {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 680px) {
  .idsk-search-results__content__page-changer.govuk-grid-column-full button {
    display: block;
  }

  .idsk-search-results__page-number.govuk-grid-column-full {
    padding-top: govuk-spacing(7);
  }
}

@media screen and (min-width: 769px) {
  .vpm-search-results-container {
    .idsk-search-results__search-bar {
      padding: 0;
    }

    .idsk-search-results__filter.govuk-grid-column-one-quarter {
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .idsk-search-results__link-panel-button {
    padding: 1rem 0;
  }
}

//Hide button with magnifier icon and show primary button
@media screen and (max-width: 640px) {
  .vpm-main-search {
    .idsk-search-component__button {
      display: none !important;
    }

    .vpm-search-job-offers-button {
      display: block !important;
    }
  }
}
