//Suitable candidates filter
.suitable-candidates-filter {
  font-family: 'Source Sans Pro', 'Arial', sans-serif;
  border-bottom: 1px solid #bfc1c3;

  &__row {
    background-color: govuk-colour('white');
  }

  &__row:nth-child(odd) {
    background-color: govuk-colour('lighter-grey');
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    font-size: 1.1875rem;
    line-height: 1.31579;
    display: inline-block;
  }

  &__item {
    font-size: 1.1875rem;
    line-height: 1.31579;
    padding: 0.3125rem;
    margin: 0.3125rem;
    border: 1px solid govuk-colour('black');
    border-radius: 4px;
    display: inline-block;
  }

  &__actionContainer {
    font-size: 1.1875rem;
    text-align: right;
  }
}

@media screen and (max-width: 640px) {
  .suitable-candidates-filter {
    &__container {
      display: block;
      text-align: left;
    }

    &__row {
      padding-bottom: 0.3125rem;
    }

    &__actionContainer {
      margin-top: 0.3125rem;
      text-align: left;
      font-size: 1rem;
    }

    &__title,
    &__item {
      font-size: 1rem;
    }
  }
}
