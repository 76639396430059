.govuk-autocomplete--error input {
  border: $govuk-border-width-form-element-error solid $govuk-error-colour;
  height: 40px;

  &:focus {
    border-color: $govuk-input-border-colour;
    box-shadow: none;
  }
}

//Accessible-autocomplete
div > [data-autocomplete] {
  font-family: 'Source Sans Pro', 'Arial', sans-serif;
}
